import { useParams } from 'react-router-dom';
import { FC, useCallback, useEffect, useState } from 'react';
//component
import SetDuplicateIngredientFormValues from './SetDuplicateIngredientFormValues';
//constants, interfaces, graphql, context
import { ParamType } from 'interfaces';
import { HTTP_STATUS } from 'constants/index';
import { IngredientPayload, useGetIngredientLazyQuery } from 'generated/graphql';

const DuplicateIngredient: FC = () => {
  const { id } = useParams<ParamType>();
  const [ingredient, setIngredient] = useState<IngredientPayload['ingredient']>(null);

  const [getIngredient, { loading }] = useGetIngredientLazyQuery({
    onCompleted: (data) => {
      const { getIngredient } = data || {};
      const { response, ingredient } = getIngredient || {};
      const { status } = response || {};
      if (status === HTTP_STATUS.SUCCESS) {
        setIngredient(ingredient as IngredientPayload['ingredient']);
      }
    },
    onError: () => {
      setIngredient(null);
    },
  });

  const fetchIngredient = useCallback(async () => {
    await getIngredient({
      variables: {
        getIngredientInput: { id: id ?? '' },
      },
    });
  }, [getIngredient, id]);

  useEffect(() => {
    id && fetchIngredient();
  }, [fetchIngredient, id]);

  return (
    <SetDuplicateIngredientFormValues
      ingredient={ingredient}
      fetchIngredient={fetchIngredient}
      loading={loading}
    />
  );
};

export default DuplicateIngredient;
