import { Box, Button, Typography } from '@mui/material';
import { FC, Fragment } from 'react';
import { FormProvider, SubmitHandler } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
// components
// import StepForm from '../Steps/StepForm';
import { Alert } from 'components/common/Alert';
import IngredientForm from '../Add/IngredientForm';
import { flexCenterBetween } from 'styles/commonComponentStyle';
import { BackdropLoader } from 'components/common/BackdropLoader';
// reducer, actions, graphql, constants, schema, context, hooks, helpers
import { formatIngredientInput } from 'lib/helper';
import { useCreateIngredientMutation } from 'generated/graphql';
import { AddNewIngredientFormType, DuplicateIngredientFormProps } from 'interfaces';
import {
  ADD_INGREDIENT_TEXT,
  DUPLICATE_INGREDIENT_TEXT,
  HTTP_STATUS,
  INGREDIENT_IS_CREATING_TEXT,
} from 'constants/index';

const DuplicateIngredientForm: FC<DuplicateIngredientFormProps> = ({ loading: getLoading, methods }) => {
  const navigate = useNavigate();

  const { handleSubmit } = methods;
  const [duplicateIngredient, { loading: duplicateIngredientLoading }] = useCreateIngredientMutation({
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,

    onCompleted: (data) => {
      const { createIngredient } = data;
      const { response } = createIngredient || {};
      const { status, message } = response || {};
      if (status === HTTP_STATUS.CREATED) {
        navigate(-1);
        Alert.success(message || '');
      }
    },

    onError: ({ message }) => {
      Alert.error(message);
    },
  });

  const onSubmit: SubmitHandler<AddNewIngredientFormType> = async (data) => {
    const createIngredientInput = formatIngredientInput(data);
    await duplicateIngredient({
      variables: {
        createIngredientInput,
      },
    });
  };

  const loading = getLoading || duplicateIngredientLoading;

  return (
    <Fragment>
      <FormProvider {...methods}>
        <Box sx={flexCenterBetween}>
          <Typography variant="h5">{DUPLICATE_INGREDIENT_TEXT}</Typography>
          <Button variant="contained" color="primary" onClick={handleSubmit(onSubmit)}>
            {ADD_INGREDIENT_TEXT}
          </Button>
        </Box>
        <IngredientForm />
      </FormProvider>
      <BackdropLoader open={loading} text={INGREDIENT_IS_CREATING_TEXT} />
    </Fragment>
  );
};

export default DuplicateIngredientForm;
