import { FC } from 'react';
import { Grid, Typography } from '@mui/material';
import { useFormContext } from 'react-hook-form';
// components
import RadioGroupController from 'controller/RadioGroupController';
import TabletCoatingRadioGroup from 'components/common/radioGroups/TabletCoating';
// constants
import {
  COLOR_TEXT,
  COATING_TEXT,
  LAYER_1_TEXT,
  LAYER_2_TEXT,
  LAYER_3_TEXT,
  SINGLE_TABLET_COLOR_MAPPED,
} from 'constants/index';
import { ProductTypes } from 'generated/graphql';
import { DosageFormulationFormType } from 'interfaces';

const TriLayer: FC = () => {
  const { setValue } = useFormContext<DosageFormulationFormType>();

  const onTabletCoatingClear = () => {
    setValue('tabletCoating', '');
  };
  return (
    <Grid item xs={12} xl={12}>
      <TabletCoatingRadioGroup
        name="tabletCoating"
        title={COATING_TEXT}
        productType={ProductTypes.Tablet}
        onClear={onTabletCoatingClear}
      />

      <Typography mt={2} variant="h6" mb={2}>
        {LAYER_1_TEXT}
      </Typography>
      <RadioGroupController
        isRequired
        name="coatingColor"
        title={COLOR_TEXT}
        options={SINGLE_TABLET_COLOR_MAPPED}
      />

      <Typography mt={2} variant="h6" mb={2}>
        {LAYER_2_TEXT}
      </Typography>

      <RadioGroupController
        isRequired
        name="coatingColor2"
        title={COLOR_TEXT}
        options={SINGLE_TABLET_COLOR_MAPPED}
      />

      <Typography mt={2} variant="h6" mb={2}>
        {LAYER_3_TEXT}
      </Typography>

      <RadioGroupController
        isRequired
        name="coatingColor3"
        title={COLOR_TEXT}
        options={SINGLE_TABLET_COLOR_MAPPED}
      />
    </Grid>
  );
};
export default TriLayer;
