import { useSearchParams } from 'react-router-dom';
import { Chip, TableCell, TableRow } from '@mui/material';
import { FC, Fragment, Reducer, useCallback, useEffect, useMemo, useReducer } from 'react';
//components
import { Alert } from 'components/common/Alert';
import TableLoader from 'components/common/TableLoader';
import NoDataFound from 'components/common/NoDataFound';
import TableContainer from 'components/common/TableContainer';
import TableComponent from 'components/common/TableComponent';
import TableSearchField from 'components/common/TableSearchField';
import TableTabsComponent from 'components/common/TableTabsComponent';
import ActionMenuDropdown from 'components/common/ActionMenuDropdown';
//constants, reducers, graphql, styles, helper
import {
  EDIT_TEXT,
  HTTP_STATUS,
  FORMULA_TABS,
  TAB_QUERY_TYPE,
  DUPLICATE_TEXT,
  LOADING_TABLE_ROWS,
  EDIT_FORMULA_ROUTE,
  FORMULA_TABLE_HEADER,
  FORMULA_DETAIL_ROUTE,
  DUPLICATE_FORMULA_ROUTE,
} from 'constants/index';
import {
  FormulaStatus,
  FormulasPayload,
  useFindAllFormulasLazyQuery,
  useUpdateFormulaStatusMutation,
} from 'generated/graphql';
import { FormulaTabType } from 'interfaces/TabTypes';
import { textWhiteSpace } from 'styles/commonComponentStyle';
import { Action, ActionType, State, initialState, formulaReducer } from 'reducer/formulaReducer';
import { formatTimeStamp, formatValue, getFormulaStatusColor } from 'lib/helper';

const FormulasTable: FC = () => {
  const [searchParams] = useSearchParams();

  const [state, dispatch] = useReducer<Reducer<State, Action>>(formulaReducer, initialState);
  const { page, rowsPerPage, search, count, data, activeTab } = state;

  const tab = searchParams.get(TAB_QUERY_TYPE);

  const [findAllFormulas, { loading: findAllFormulasLoading, error }] = useFindAllFormulasLazyQuery({
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'no-cache',
    notifyOnNetworkStatusChange: true,

    onCompleted: (data) => {
      const { findAllFormulas } = data;
      const { pagination, response, data: formulaData } = findAllFormulas || {};
      const { status } = response || {};
      if (status === HTTP_STATUS.SUCCESS) {
        const { page, totalCount } = pagination || {};
        dispatch({ type: ActionType.SET_PAGE, page: page || 1 });
        dispatch({ type: ActionType.SET_COUNT, count: totalCount || 0 });
        dispatch({ type: ActionType.SET_DATA, data: formulaData as FormulasPayload['data'] });
      } else {
        resetPage();
      }
    },

    onError: () => {
      resetPage();
    },
  });

  const [updateFormulaStatus, { loading: updateFormulaStatusLoading }] = useUpdateFormulaStatusMutation({
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,

    onCompleted: (data) => {
      const { updateFormulaStatus } = data;
      const { response } = updateFormulaStatus || {};
      const { message, status } = response || {};
      if (status === HTTP_STATUS.SUCCESS) {
        Alert.success(message || '');
        fetchFormulas();
      } else {
        Alert.error(message ?? '');
      }
    },

    onError: ({ message }) => {
      Alert.error(message);
    },
  });

  const resetPage = () => {
    dispatch({ type: ActionType.SET_PAGE, page: 1 });
    dispatch({ type: ActionType.SET_COUNT, count: 0 });
    dispatch({ type: ActionType.SET_DATA, data: [] });
  };

  const fetchFormulas = useCallback(async () => {
    await findAllFormulas({
      variables: {
        findAllFormulasInput: {
          search,
          paginationOptions: {
            limit: rowsPerPage,
            page,
          },
          ...(activeTab !== 'all' && { status: activeTab }),
        },
      },
    });
  }, [findAllFormulas, search, rowsPerPage, page, activeTab]);

  useEffect(() => {
    (!search.length || search.length > 2) && fetchFormulas();
  }, [fetchFormulas, search.length]);

  const tabHandler = (_: React.SyntheticEvent<Element, Event>, value: string) => {
    dispatch({ type: ActionType.SET_ACTIVE_TAB, activeTab: value as FormulaTabType });
  };

  const onActiveHandler = async (id: string, isActive: boolean) => {
    await updateFormulaStatus({
      variables: {
        updateFormulaStatusInput: {
          id,
          status: isActive ? FormulaStatus.Inactive : FormulaStatus.Active,
        },
      },
    });
  };

  const onSearch = (val: string) => {
    dispatch({ type: ActionType.SET_PAGE, page: 1 });
    dispatch({ type: ActionType.SET_SEARCH, search: val });
  };

  const onClear = () => {
    dispatch({ type: ActionType.SET_PAGE, page: 1 });
    dispatch({ type: ActionType.SET_SEARCH, search: '' });
  };

  useMemo(() => {
    if (tab === 'all') dispatch({ type: ActionType.SET_ACTIVE_TAB, activeTab: 'all' });
  }, [tab]);

  const loading = findAllFormulasLoading || updateFormulaStatusLoading;

  const noData = Boolean((!findAllFormulasLoading && data?.length === 0) || error);

  return (
    <TableContainer>
      <TableTabsComponent tabsList={FORMULA_TABS} activeTab={activeTab} tabHandler={tabHandler} />

      <TableSearchField search={search} onChange={onSearch} onClear={onClear} />

      <TableComponent
        noData={noData}
        page={page - 1}
        count={count}
        rowsPerPage={rowsPerPage}
        tableHeader={FORMULA_TABLE_HEADER(activeTab)}
        setPage={(p: number) => dispatch({ type: ActionType.SET_PAGE, page: p + 1 })}
        setRowsPerPage={(r: number) => dispatch({ type: ActionType.SET_ROWS_PER_PAGE, rowsPerPage: r })}>
        {loading ? (
          <TableLoader columns={6} rows={LOADING_TABLE_ROWS} />
        ) : (
          <Fragment>
            {data?.map((cell) => {
              const { id, name, createdAt, status, productType, user, productFormulationType } = cell || {};
              const { name: productName } = productType || {};
              const { name: productFormulationTypeName } = productFormulationType || {};
              const { firstName, lastName } = user || {};

              return (
                <TableRow key={id}>
                  <TableCell sx={textWhiteSpace}>{name ?? '--'}</TableCell>
                  <TableCell>{productName ?? '--'}</TableCell>
                  <TableCell sx={textWhiteSpace}>{productFormulationTypeName ?? '--'}</TableCell>
                  {activeTab === 'all' && (
                    <TableCell>
                      <Chip
                        size="small"
                        variant="outlined"
                        label={formatValue(status as FormulaStatus)}
                        color={getFormulaStatusColor(status as FormulaStatus)}
                      />
                    </TableCell>
                  )}

                  <TableCell>
                    {firstName ?? '--'} {lastName ?? ''}
                  </TableCell>

                  <TableCell>{createdAt ? formatTimeStamp(createdAt, 'MM/DD/YY') : '--'}</TableCell>
                  <TableCell>
                    <ActionMenuDropdown
                      id={id ?? ''}
                      editText={status === FormulaStatus.Draft ? EDIT_TEXT : DUPLICATE_TEXT}
                      isActive={status === FormulaStatus.Active}
                      editRoute={
                        status === FormulaStatus.Draft ? EDIT_FORMULA_ROUTE : DUPLICATE_FORMULA_ROUTE
                      }
                      viewRoute={status !== FormulaStatus.Draft ? FORMULA_DETAIL_ROUTE : ''}
                      onActiveHandler={status !== FormulaStatus.Draft ? onActiveHandler : undefined}
                    />
                  </TableCell>
                </TableRow>
              );
            })}
          </Fragment>
        )}
      </TableComponent>

      <NoDataFound noData={noData} />
    </TableContainer>
  );
};

export default FormulasTable;
