import { FC, Fragment } from 'react';
import { TableRow, TableCell, Chip } from '@mui/material';
// components
import {
  INACTIVE_TEXT,
  LOADING_TABLE_ROWS,
  INGREDIENT_SYRUP_TYPE_TABLE_HEADER,
} from '../../../../../../constants';
import NoDataFound from '../../../../../common/NoDataFound';
import TableLoader from '../../../../../common/TableLoader';
import TableComponent from '../../../../../common/TableComponent';
import ActionMenuDropdown from '../../../../../common/ActionMenuDropdown';
// graphql, reducer,
import { IngredientSyrupTableProps } from '../../../../../../interfaces';
import { textWhiteSpace } from '../../../../../../styles/commonComponentStyle';

const Table: FC<IngredientSyrupTableProps> = ({ loading = false, data, onDelete, onEdit, noData }) => {
  return (
    <Fragment>
      <TableComponent noData tableHeader={INGREDIENT_SYRUP_TYPE_TABLE_HEADER}>
        {loading ? (
          <TableLoader columns={3} rows={LOADING_TABLE_ROWS} />
        ) : (
          <Fragment>
            {data?.map((cell) => {
              const { id, percentage, productIngredients } = cell || {};
              const { name, isActive } = productIngredients || {};

              return (
                <TableRow key={id}>
                  <TableCell
                    sx={{
                      ...textWhiteSpace,
                      ...(isActive ? {} : { fontStyle: 'italic' }),
                    }}>
                    {name ?? '--'}{' '}
                    {!isActive && (
                      <Chip size="small" variant="outlined" label={INACTIVE_TEXT} color={'error'} />
                    )}
                  </TableCell>
                  <TableCell>{percentage ? `${percentage}%` : '--'}</TableCell>
                  <TableCell>
                    <ActionMenuDropdown id={id ?? ''} onDelete={onDelete} onEdit={() => onEdit(cell)} />
                  </TableCell>
                </TableRow>
              );
            })}
          </Fragment>
        )}
      </TableComponent>
      <NoDataFound noData={noData} />
    </Fragment>
  );
};

export default Table;
