import { useNavigate } from 'react-router-dom';
import { IconButton, Menu, MenuItem } from '@mui/material';
import { FC, Fragment, MouseEvent, useState } from 'react';
import { MoreVert as MoreVertIcon } from '@mui/icons-material';
// constants, interface
import { ActionMenuDropdownProps } from 'interfaces';
import {
  ACTIVATE_TEXT,
  DEACTIVATE_TEXT,
  DUPLICATE_TEXT,
  EDIT_TEXT,
  REMOVE_TEXT,
  VIEW_TEXT,
} from 'constants/index';

const ActionMenuDropdown: FC<ActionMenuDropdownProps> = ({
  id,
  isActive,
  editRoute,
  viewRoute,
  duplicateRoute,
  onEdit,
  onDelete,
  onActiveHandler,
  viewText = VIEW_TEXT,
  editText = EDIT_TEXT,
  duplicateText = DUPLICATE_TEXT,
}) => {
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const editHandler = () => {
    if (onEdit) {
      handleClose();
      onEdit && onEdit();
    } else {
      navigate(`${editRoute ?? ''}/${id}`);
    }
  };

  return (
    <Fragment>
      <IconButton
        id="basic-button"
        aria-haspopup="true"
        onClick={handleClick}
        aria-expanded={open ? 'true' : undefined}
        aria-controls={open ? 'basic-menu' : undefined}>
        <MoreVertIcon />
      </IconButton>

      <Menu
        open={open}
        id="basic-menu"
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}>
        {viewRoute && <MenuItem onClick={() => navigate(`${viewRoute}/${id}`)}>{viewText}</MenuItem>}
        {(onEdit || editRoute) && <MenuItem onClick={editHandler}>{editText}</MenuItem>}
        {onDelete && <MenuItem onClick={() => onDelete(id)}>{REMOVE_TEXT}</MenuItem>}
        {duplicateRoute && (
          <MenuItem onClick={() => navigate(`${duplicateRoute}/${id}`)}>{duplicateText}</MenuItem>
        )}
        {onActiveHandler && (
          <MenuItem onClick={() => onActiveHandler(id ?? '', isActive ?? false)}>
            {isActive ? DEACTIVATE_TEXT : ACTIVATE_TEXT}
          </MenuItem>
        )}
      </Menu>
    </Fragment>
  );
};

export default ActionMenuDropdown;
