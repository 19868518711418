import { useNavigate } from 'react-router-dom';
import { Box, Chip, Grid, TableCell, TableRow } from '@mui/material';
import { FC, Fragment, Reducer, useCallback, useEffect, useReducer } from 'react';
// components
import { Alert } from 'components/common/Alert';
import Selector from 'components/common/Selector';
import TableLoader from 'components/common/TableLoader';
import NoDataFound from 'components/common/NoDataFound';
import TableContainer from 'components/common/TableContainer';
import TableComponent from 'components/common/TableComponent';
import TableSearchField from 'components/common/TableSearchField';
import ActionMenuDropdown from 'components/common/ActionMenuDropdown';
import TableTabsComponent from 'components/common/TableTabsComponent';
import ProductTypesSelect from 'components/common/simpleSelectors/ProductType';
import ProductFormulationTypes from 'components/common/simpleSelectors/ProductFormulationType';
// constants, reducers, graphql, styles , interfaces and helper
import { formatValue } from 'lib/helper';
import { SelectType } from 'interfaces';
import {
  TABS,
  HTTP_STATUS,
  TabEnum,
  LOADING_TABLE_ROWS,
  SWEETENER_TABLE_HEADER,
  ACTIVE_TEXT,
  INACTIVE_TEXT,
  PRODUCT_TYPE_TEXT,
  PRODUCT_FORMULATION_TEXT,
  SIZE_TEXT,
  SWEETENER_NATURE_MAPPED,
  NATURE_TEXT,
  EDIT_SWEETENER_ROUTE,
  SWEETENER_ELEMENT_ROUTES,
  SWEETENER_SIZE_MAPPED,
} from 'constants/index';
import { cursorPointer } from 'styles/commonComponentStyle';
import {
  SweetenerType,
  SweetenersPayload,
  useUpdateSweetenerMutation,
  useFindAllSweetenersTableLazyQuery,
  SweetenerSizeType,
  SweetenerNatureType,
} from 'generated/graphql';
import { State, Action, ActionType, initialState, sweetenerReducer } from 'reducer/sweetenerReducer';

const SweetenerSystemTable: FC = () => {
  const [state, dispatch] = useReducer<Reducer<State, Action>>(sweetenerReducer, initialState);
  const {
    page,
    rowsPerPage,
    search,
    count,
    data,
    activeTab,
    nature,
    productFormulation,
    productSize,
    productType,
    size,
  } = state;

  const { value: productTypeId } = productType;
  const { value: productFormulationTypeId } = productFormulation;
  const { value: productSizeId } = productSize;

  const navigate = useNavigate();
  const [findAllSweeteners, { loading: fetchLoading, error }] = useFindAllSweetenersTableLazyQuery({
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'no-cache',
    notifyOnNetworkStatusChange: true,

    onCompleted: (data) => {
      const { findAllSweeteners } = data;
      const { response, data: sweeteners, pagination } = findAllSweeteners || {};
      const { status } = response || {};
      if (status === HTTP_STATUS.SUCCESS) {
        const { page, totalCount } = pagination || {};
        dispatch({ type: ActionType.SET_PAGE, page: page || 1 });
        dispatch({ type: ActionType.SET_COUNT, count: totalCount || 0 });
        dispatch({ type: ActionType.SET_DATA, data: sweeteners as SweetenersPayload['data'] });
      } else {
        resetPage();
      }
    },

    onError: () => {
      resetPage();
    },
  });

  const [updateSweetenerStatus, { loading: updateLoading }] = useUpdateSweetenerMutation({
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,

    onCompleted: (data) => {
      const { updateSweetener } = data;
      const { response } = updateSweetener || {};
      const { message, status } = response || {};
      if (status === HTTP_STATUS.SUCCESS) {
        Alert.success(message || '');
        fetchAllSweeteners();
      } else {
        Alert.error(message ?? '');
      }
    },

    onError: ({ message }) => {
      Alert.error(message);
    },
  });

  const resetPage = () => {
    dispatch({ type: ActionType.SET_PAGE, page: 1 });
    dispatch({ type: ActionType.SET_COUNT, count: 0 });
    dispatch({ type: ActionType.SET_DATA, data: [] });
  };

  const fetchAllSweeteners = useCallback(async () => {
    await findAllSweeteners({
      variables: {
        findAllSweetenersInput: {
          paginationOptions: {
            limit: rowsPerPage,
            page,
          },
          search,
          ...(activeTab !== TabEnum.All && { isActive: activeTab === TabEnum.Active }),
          ...(size && { size: size as SweetenerSizeType }),
          ...(nature && { nature: nature as SweetenerNatureType }),
          ...(productTypeId && { productTypeId }),
          ...(productFormulationTypeId && { productFormulationTypeId }),
          ...(productSizeId && { productSizeId }),
          type: SweetenerType.Sweetener,
        },
      },
    });
  }, [
    findAllSweeteners,
    rowsPerPage,
    page,
    search,
    activeTab,
    size,
    nature,
    productTypeId,
    productFormulationTypeId,
    productSizeId,
  ]);

  useEffect(() => {
    (!search.length || search.length > 2) && fetchAllSweeteners();
  }, [fetchAllSweeteners, search.length]);

  const tabHandler = (_: React.SyntheticEvent<Element, Event>, value: string) => {
    dispatch({ type: ActionType.SET_ACTIVE_TAB, activeTab: value as TabEnum });
  };

  const onActiveHandler = async (id: string, status: boolean) => {
    await updateSweetenerStatus({
      variables: {
        updateSweetenerInput: {
          id,
          isActive: !status,
        },
      },
    });
  };

  const onSizeChange = (item: string) => dispatch({ type: ActionType.SET_SIZE, size: item });
  const onNatureChange = (item: string) => dispatch({ type: ActionType.SET_NATURE, nature: item });

  const loading = fetchLoading || updateLoading;

  const noData = Boolean((!fetchLoading && data?.length === 0) || error);

  const onProductTypeChange = useCallback((item: SelectType) => {
    dispatch({ type: ActionType.SET_PRODUCT_TYPE, productType: item });
    dispatch({
      type: ActionType.SET_PRODUCT_FORMULATION,
      productFormulation: initialState.productFormulation,
    });
  }, []);

  return (
    <TableContainer>
      <TableTabsComponent activeTab={activeTab} tabsList={TABS} tabHandler={tabHandler} />

      <Box m={({ spacing }) => spacing(2, 3)}>
        <Grid container spacing={2}>
          <Grid item lg={4} md={6} sm={12} xs={12}>
            <TableSearchField
              withSpacing={false}
              search={search}
              onClear={() => dispatch({ type: ActionType.SET_SEARCH, search: '' })}
              onChange={(val) => dispatch({ type: ActionType.SET_SEARCH, search: val })}
            />
          </Grid>
          <Grid item lg={4} md={6} sm={12} xs={12}>
            <ProductTypesSelect
              isClearable
              value={productType}
              name="product-type"
              title={PRODUCT_TYPE_TEXT}
              handleChange={onProductTypeChange}
            />
          </Grid>
          <Grid item lg={4} md={6} sm={12} xs={12}>
            <ProductFormulationTypes
              isClearable
              value={productFormulation}
              name="product-formulation"
              title={PRODUCT_FORMULATION_TEXT}
              productTypeId={productTypeId}
              handleChange={(item) =>
                dispatch({ type: ActionType.SET_PRODUCT_FORMULATION, productFormulation: item })
              }
            />
          </Grid>

          <Grid item lg={4} md={6} sm={12} xs={12}>
            <Selector
              addAll
              displayEmpty
              value={size}
              id="size"
              title={SIZE_TEXT}
              options={SWEETENER_SIZE_MAPPED}
              onChange={onSizeChange}
            />
          </Grid>

          <Grid item lg={4} md={6} sm={12} xs={12}>
            <Selector
              addAll
              displayEmpty
              value={nature}
              id="nature"
              title={NATURE_TEXT}
              options={SWEETENER_NATURE_MAPPED}
              onChange={onNatureChange}
            />
          </Grid>
        </Grid>
      </Box>

      <TableComponent
        noData={noData}
        page={page - 1}
        count={count}
        rowsPerPage={rowsPerPage}
        tableHeader={SWEETENER_TABLE_HEADER}
        setPage={(p: number) => dispatch({ type: ActionType.SET_PAGE, page: p + 1 })}
        setRowsPerPage={(r: number) => dispatch({ type: ActionType.SET_ROWS_PER_PAGE, rowsPerPage: r })}>
        {loading ? (
          <TableLoader columns={10} rows={LOADING_TABLE_ROWS} />
        ) : (
          <Fragment>
            {data?.map((cell) => {
              const {
                id,
                name,
                costPerServing,
                mgPerServing,
                nature,
                size,
                type,
                isActive,
                productType,
                productFormulationType,
                productSize,
              } = cell || {};

              const { name: productTypeName } = productType || {};
              const { name: productSizeName } = productSize || {};
              const { name: productFormulationTypeName } = productFormulationType || {};

              return (
                <TableRow key={id}>
                  <TableCell sx={cursorPointer} onClick={() => navigate(`${SWEETENER_ELEMENT_ROUTES}/${id}`)}>
                    <b>{name ?? '--'}</b>
                  </TableCell>
                  <TableCell>{formatValue(nature ?? '--')}</TableCell>
                  <TableCell>{formatValue(size || '--')}</TableCell>

                  <TableCell>{formatValue(type ?? '--')}</TableCell>
                  <TableCell>{productTypeName ?? '--'}</TableCell>
                  <TableCell>{productSizeName ?? '--'}</TableCell>
                  <TableCell>{productFormulationTypeName ?? '--'}</TableCell>
                  <TableCell>{mgPerServing ? `${mgPerServing}` : '--'}</TableCell>
                  <TableCell>{costPerServing ? `$${costPerServing}` : '--'}</TableCell>
                  <TableCell>
                    <Chip
                      size="small"
                      variant="outlined"
                      label={isActive ? ACTIVE_TEXT : INACTIVE_TEXT}
                      color={isActive ? 'success' : 'error'}
                    />
                  </TableCell>
                  <TableCell>
                    <ActionMenuDropdown
                      id={id ?? ''}
                      isActive={!!isActive}
                      editRoute={EDIT_SWEETENER_ROUTE}
                      onActiveHandler={onActiveHandler}
                      viewRoute={SWEETENER_ELEMENT_ROUTES}
                    />
                  </TableCell>
                </TableRow>
              );
            })}
          </Fragment>
        )}
      </TableComponent>

      <NoDataFound noData={noData} />
    </TableContainer>
  );
};

export default SweetenerSystemTable;
