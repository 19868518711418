import { TableCell, TableRow } from '@mui/material';
import { FC, Fragment, Reducer, useCallback, useEffect, useReducer } from 'react';
// components
import { Alert } from 'components/common/Alert';
import TableLoader from 'components/common/TableLoader';
import NoDataFound from 'components/common/NoDataFound';
import TableContainer from 'components/common/TableContainer';
import TableComponent from 'components/common/TableComponent';
import IngredientsTableFilter from './IngredientsTableFilter';
import TableTabsComponent from 'components/common/TableTabsComponent';
import ActionMenuDropdown from 'components/common/ActionMenuDropdown';
// constants, reducers, graphql, styles
import {
  IngredientsPayload,
  useFindAllIngredientsLazyQuery,
  useUpdateIngredientStatusMutation,
} from 'generated/graphql';
import {
  TABS,
  TabEnum,
  HTTP_STATUS,
  LOADING_TABLE_ROWS,
  EDIT_INGREDIENT_ROUTE,
  INGREDIENT_TABLE_HEADER,
  DUPLICATE_TEXT,
  DUPLICATE_INGREDIENT_ROUTE,
} from 'constants/index';
import { textWhiteSpace } from 'styles/commonComponentStyle';
import { Action, ActionType, State, initialState, ingredientReducer } from 'reducer/ingredientReducer';

const IngredientsTable: FC = () => {
  const [state, dispatch] = useReducer<Reducer<State, Action>>(ingredientReducer, initialState);
  const { page, rowsPerPage, search, count, data, isActive } = state;

  const [findAllIngredients, { loading: findAllIngredientsLoading, error }] = useFindAllIngredientsLazyQuery({
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'no-cache',
    notifyOnNetworkStatusChange: true,

    onCompleted: (data) => {
      const { findAllIngredients } = data;
      const { pagination, response, data: ingredientData } = findAllIngredients || {};
      const { status } = response || {};
      if (status === HTTP_STATUS.SUCCESS) {
        const { page, totalCount } = pagination || {};
        dispatch({ type: ActionType.SET_PAGE, page: page || 1 });
        dispatch({ type: ActionType.SET_COUNT, count: totalCount || 0 });
        dispatch({ type: ActionType.SET_DATA, data: ingredientData as IngredientsPayload['data'] });
      } else {
        resetPage();
      }
    },

    onError: () => {
      resetPage();
    },
  });

  const [updateIngredientStatus, { loading: updateIngredientStatusLoading }] =
    useUpdateIngredientStatusMutation({
      fetchPolicy: 'network-only',
      notifyOnNetworkStatusChange: true,

      onCompleted: (data) => {
        const { updateIngredientStatus } = data;
        const { response } = updateIngredientStatus || {};
        const { message, status } = response || {};
        if (status === HTTP_STATUS.SUCCESS) {
          Alert.success(message || '');
          fetchIngredients();
        } else {
          Alert.error(message ?? '');
        }
      },

      onError: ({ message }) => {
        Alert.error(message);
      },
    });

  const resetPage = () => {
    dispatch({ type: ActionType.SET_PAGE, page: 1 });
    dispatch({ type: ActionType.SET_COUNT, count: 0 });
    dispatch({ type: ActionType.SET_DATA, data: [] });
  };

  const fetchIngredients = useCallback(async () => {
    await findAllIngredients({
      variables: {
        findAllIngredientsInput: {
          search,
          paginationOptions: {
            limit: rowsPerPage,
            page,
          },
          ...(isActive !== TabEnum.All && { isActive: isActive === TabEnum.Active ? true : false }),
        },
      },
    });
  }, [findAllIngredients, search, rowsPerPage, page, isActive]);

  useEffect(() => {
    (!search.length || search.length > 2) && fetchIngredients();
  }, [fetchIngredients, search.length]);

  const tabHandler = (_: React.SyntheticEvent<Element, Event>, value: string) => {
    dispatch({ type: ActionType.SET_IS_ACTIVE, isActive: value as TabEnum });
    dispatch({ type: ActionType.SET_PAGE, page: 1 });
  };

  const onActiveHandler = async (id: string, isActive: boolean) => {
    await updateIngredientStatus({
      variables: {
        UpdateIngredientStatus: {
          id,
          isActive: isActive ? false : true,
        },
      },
    });
  };

  const loading = findAllIngredientsLoading || updateIngredientStatusLoading;

  const noData = Boolean((!findAllIngredientsLoading && data?.length === 0) || error);

  return (
    <TableContainer>
      <TableTabsComponent activeTab={isActive} tabHandler={tabHandler} tabsList={TABS} />

      <IngredientsTableFilter dispatch={dispatch} state={state} />

      <TableComponent
        noData={noData}
        page={page - 1}
        count={count}
        rowsPerPage={rowsPerPage}
        tableHeader={INGREDIENT_TABLE_HEADER}
        setPage={(p: number) => dispatch({ type: ActionType.SET_PAGE, page: p + 1 })}
        setRowsPerPage={(r: number) => dispatch({ type: ActionType.SET_ROWS_PER_PAGE, rowsPerPage: r })}>
        {loading ? (
          <TableLoader columns={5} rows={LOADING_TABLE_ROWS} />
        ) : (
          <Fragment>
            {data?.map((cell) => {
              const { id, cost, category, name, subCategory, isActive } = cell || {};
              const { name: categoryName } = category || {};
              const { name: subCategoryName } = subCategory || {};

              return (
                <TableRow key={id}>
                  <TableCell sx={textWhiteSpace}>{name ?? '--'}</TableCell>
                  <TableCell>{categoryName ?? '--'}</TableCell>
                  <TableCell>{subCategoryName ?? '--'}</TableCell>
                  <TableCell>{`$${cost}`}</TableCell>

                  <TableCell>
                    <ActionMenuDropdown
                      id={id ?? ''}
                      isActive={!!isActive}
                      duplicateText={DUPLICATE_TEXT}
                      editRoute={EDIT_INGREDIENT_ROUTE}
                      onActiveHandler={onActiveHandler}
                      duplicateRoute={DUPLICATE_INGREDIENT_ROUTE}
                    />
                  </TableCell>
                </TableRow>
              );
            })}
          </Fragment>
        )}
      </TableComponent>

      <NoDataFound noData={noData} />
    </TableContainer>
  );
};

export default IngredientsTable;
