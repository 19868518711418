import { useParams } from 'react-router-dom';
import { AddRounded as AddRoundedIcon } from '@mui/icons-material';
import { FC, Fragment, JSX, Reducer, useCallback, useEffect, useReducer, useState } from 'react';
import { Box, Button, Chip, TableCell, TableRow, Typography } from '@mui/material';
// components
import EditSweetenerElement from './Edit';
import { Alert } from 'components/common/Alert';
import NoDataFound from 'components/common/NoDataFound';
import TableLoader from 'components/common/TableLoader';
import EmptyTableTab from 'components/common/EmptyTableTab';
import TableContainer from 'components/common/TableContainer';
import TableComponent from 'components/common/TableComponent';
import SweetenerCard from './components/SweetenerSystemCard';
import ActionMenuDropdown from 'components/common/ActionMenuDropdown';
// constant, styles, interfaces
import {
  HTTP_STATUS,
  ADD_NEW_TEXT,
  LOADING_TABLE_ROWS,
  SWEETENER_ELEMENT_TABLE_HEADER,
  SWEETENER_ELEMENT_TEXT,
  INACTIVE_TEXT,
} from 'constants/index';
import {
  useFindAllSweetenerElementsLazyQuery,
  SweetenerElementsPayload,
  SweetenerPayload,
  useRemoveSweetenerElementMutation,
  SweetenerElementPayload,
} from 'generated/graphql';
import { ParamType } from 'interfaces';
import { flexCenterBetween, textWhiteSpace } from 'styles/commonComponentStyle';
import {
  State,
  Action,
  ActionType,
  initialState,
  SweetenerElementReducer,
} from 'reducer/sweetenerElementsReducer';

const SweetenerElementTable: FC = (): JSX.Element => {
  const params = useParams<ParamType>();
  const { sweetenerId } = params || {};
  const [open, setOpen] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [state, dispatch] = useReducer<Reducer<State, Action>>(SweetenerElementReducer, initialState);
  const { data, sweetener, sweetenerElement } = state;
  const [findAllSweetenerElement, { loading: fetchLoading, error }] = useFindAllSweetenerElementsLazyQuery({
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'no-cache',
    notifyOnNetworkStatusChange: true,

    onCompleted: (data) => {
      const { getSweetener } = data;
      const { response, sweetener } = getSweetener || {};
      const { status } = response || {};
      if (status === HTTP_STATUS.SUCCESS) {
        const { sweetenerElements, ...rest } = sweetener || {};

        dispatch({ type: ActionType.SET_DATA, data: sweetenerElements as SweetenerElementsPayload['data'] });
        dispatch({
          type: ActionType.SET_SWEETENER,
          sweetener: rest as SweetenerPayload['sweetener'],
        });
      } else {
        resetPage();
      }
    },

    onError: () => {
      resetPage();
    },
  });

  const [removeSweetenerElement, { loading: delLoading }] = useRemoveSweetenerElementMutation({
    onCompleted: (data) => {
      const { removeSweetenerElement } = data;
      const { response } = removeSweetenerElement || {};
      const { status, message } = response || {};
      if (status === HTTP_STATUS.SUCCESS) {
        Alert.success(message ?? '');
        fetchSweetenerElements();
      } else {
        Alert.error(message ?? '');
      }
    },

    onError: ({ message }) => {
      Alert.error(message);
    },
  });

  const onDelete = async (delId: string) => {
    try {
      await removeSweetenerElement({
        variables: {
          removeSweetenerElementsInput: {
            id: delId,
          },
        },
      });
    } catch (error) {}
  };

  const resetPage = () => {
    dispatch({ type: ActionType.SET_DATA, data: [] });
  };

  const fetchSweetenerElements = useCallback(async () => {
    if (sweetenerId) {
      await findAllSweetenerElement({
        variables: {
          getSweetenerInput: {
            id: sweetenerId,
          },
        },
      });
    }
  }, [findAllSweetenerElement, sweetenerId]);

  useEffect(() => {
    sweetenerId && fetchSweetenerElements();
  }, [fetchSweetenerElements, sweetenerId]);

  const loading = fetchLoading || delLoading;

  const noData = Boolean((!fetchLoading && data?.length === 0) || error);

  const onClose = () => {
    setOpen(false);
    if (isEdit) {
      setIsEdit(false);
      dispatch({ type: ActionType.SET_SWEETENER_COMPONENT, sweetenerElement: null });
    }
  };

  const editHandler = (cell: SweetenerElementPayload['sweetenerElement']) => {
    setIsEdit(true);
    setOpen(true);
    dispatch({
      type: ActionType.SET_SWEETENER_COMPONENT,
      sweetenerElement: cell,
    });
  };

  return (
    <Fragment>
      <Box sx={flexCenterBetween}>
        <Typography variant="h5">{SWEETENER_ELEMENT_TEXT}</Typography>
        <Button variant="contained" color="primary" onClick={() => setOpen(true)}>
          <AddRoundedIcon />
          {ADD_NEW_TEXT}
        </Button>
      </Box>

      <TableContainer>
        <EmptyTableTab />

        <SweetenerCard sweetener={sweetener} loading={loading} />
        <TableComponent noData tableHeader={SWEETENER_ELEMENT_TABLE_HEADER}>
          {loading ? (
            <TableLoader columns={3} rows={LOADING_TABLE_ROWS} />
          ) : (
            <Fragment>
              {data?.map((cell) => {
                const { id, sweetenerComponent, qtPerMg } = cell || {};
                const { name: componentName, isActive } = sweetenerComponent || {};

                return (
                  <TableRow key={id}>
                    <TableCell
                      sx={{
                        ...textWhiteSpace,
                        ...(isActive ? {} : { fontStyle: 'italic' }),
                      }}>
                      {componentName ?? '--'}{' '}
                      {!isActive && (
                        <Chip size="small" variant="outlined" label={INACTIVE_TEXT} color={'error'} />
                      )}
                    </TableCell>
                    <TableCell sx={textWhiteSpace}>{qtPerMg ? `${qtPerMg}` : '--'}</TableCell>
                    <TableCell>
                      <ActionMenuDropdown
                        id={id ?? ''}
                        onDelete={onDelete}
                        onEdit={() => editHandler(cell)}
                      />
                    </TableCell>
                  </TableRow>
                );
              })}
            </Fragment>
          )}
        </TableComponent>
        <NoDataFound noData={noData} />
      </TableContainer>

      <EditSweetenerElement
        open={open}
        isEdit={isEdit}
        onClose={onClose}
        fetch={fetchSweetenerElements}
        sweetenerElement={sweetenerElement}
      />
    </Fragment>
  );
};

export default SweetenerElementTable;
